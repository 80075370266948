.mainFrame-invoice-formation {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 25px;
    padding-top: 25px;
}
.mainFrame-invoice-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}
.mainFrame-invoice-formation_title {
    text-align: left;
    font-weight: 600;
    background-image: var(--main-grad);
    color: transparent;
    display: inline-block;
    width: max-content;
    background-clip: text;
    width: fit-content;
}
.clickable {
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.clickable:hover {
    transform: scale(1.05);
}

.invoice-selecting-params {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.invoce-currency {
    width: 100%;
    font-size: 20px;
    border-radius: 20px;
    padding: 10px 15px;
}

.invoice-currency-block {
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;
}

.invoce-formation {
    display: block;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    color: rgb(255, 255, 255) !important;
    text-decoration: underline;
    text-align: center;
    word-wrap: break-word;
}

.invoce-link-share {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.invoice-selecting-params > div > h3 {
    margin: 0;
    margin-bottom: 10px;
}

.btn-share {
    border-radius: 20px;
    margin-top: 20px;
    padding: 12px 30px;
    background-color: var(--small-btn-bg);
}

.currency-img {
    background-repeat: no-repeat;
    background-size: 100%;
    width: 32px;
    height: 32px;
    padding: 12px;
    border-radius: 40px;
    border: solid 2px inherit;
}

.currency-img-active {
    border: solid 2px var(--text-grey);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 32px;
    height: 32px;
    padding: 12px;
    border-radius: 40px;
}

.currency-img-active-payment {
    border: solid 2px var(--text-grey);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 64px;
    height: 32px;
    padding: 12px;
    border-radius: 40px;
}

.currency-img-payment {
    background-repeat: no-repeat;
    background-size: 100%;
    width: 64px;
    height: 32px;
    padding: 12px;
    border-radius: 40px;
    border: solid 2px inherit;
}
.invoice-context {
    position: sticky;
    top: 0;
    box-shadow: 1px 4px 7px var(--body-bg);
    border: solid 1px var(--body-bg);
}
.invoice-context > ul {
    font-size: 20px;
    line-height: 32px;
    width: 100%;

    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.invoice-context > ul > li > span:nth-child(1) {
    font-size: 16px;

    text-overflow: ellipsis;

    overflow: hidden;
    white-space: nowrap;
}

.invoice-context > ul > li {
    letter-spacing: 0.2px;
    display: flex;
    justify-content: start;
}
.invoice-context > ul > li:first-child > span:nth-child(3) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.invoice-context > ul > li:last-child {
    flex-wrap: wrap;
}

.invoice-context > ul > li > span:nth-child(2) {
    border-bottom: dotted 2px var(--text-grey);
    flex-grow: 5;
    height: 24px;
}

.invoice-context > ul > li > span:nth-child(3) {
    font-size: 24px;
}

.checkForPayment {
    padding-bottom: 20px;
}

.checkForPayment > span:first-child {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.ckeck-box {
    background-repeat: no-repeat;
    background-size: 100%;
    display: block;
    width: 20px;
    height: 20px;
}

.invoice-input_selecting {
    white-space: nowrap;
    font-size: 22px;
    border-bottom: solid 2px var(--text-grey);
}

.myProjectsSelecting {
    font-size: 24px;
    font-weight: 600;
}

.invoice_gate-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0px;
}

.order-type {
    display: flex;
    flex-direction: column;
    gap: 19px;
}

.order-type > h3 {
    margin: 0;
}

.order-type_selected_List {
    font-size: 24px;
    font-weight: 600;
}

.gradient-element {
    background-image: var(--main-grad);
    color: transparent;
    background-clip: text;
}

.PaymentMethod-invoice {
    text-transform: uppercase;
}

@media screen and (max-width: 410px) {
    .invoice-context > ul {
        font-size: 14px;
    }

    .invoice-context > ul > li > span:nth-child(3) {
        font-size: 20px;
    }

    .invoice-context > ul > li > span:nth-child(1) {
        font-size: 14px;
    }
}

.animate-txt {
    font-size: 26px;
    background-image: var(--main-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: txt 2s ease infinite;
}

@keyframes txt {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

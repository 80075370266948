.choozeMenu {
    display: flex;
    justify-content: center;
}
.choozeMenu > div {
    font-size: 20px;
    min-width: 50%;
    padding: 10px 0px;
    text-align: center;
    letter-spacing: 1.3px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.activeTab {
    width: 100%;
    height: 3px;
    background: var(--main-grad);
}

.editHead {
    width: 100%;
}

.form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 35px;
    font-size: 24px;
    padding: 25px 25px 35px 25px !important;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--element-bg);
}

.btnAccept {
    display: flex;
    justify-content: center;
}

.checkedMenu {
    position: relative;
    right: 18px;
    bottom: 5px;

    display: flex;
    justify-content: left;
    align-items: center;
}

.wallDiv {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.wallOpener {
    font-size: 20px;
    color: #fff;
    margin-bottom: 9px;

    cursor: pointer;
}

.walletsSpan {
    font-size: 18px;
    color: #fff;
}

.walletLine {
    background-color: var(--text-grey);
    width: 100%;
    height: 2px;
    opacity: 1;
}

.statusDiv {
    display: flex;
    align-items: center;

    cursor: pointer;

    background-color: var(--element-bg);
}

.status {
    font-size: 24px;

    padding-left: 20px;
}

.formStatus {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    text-align: center;

    margin: 2px 0 0 20px;
    background: var(--main-grad);
    border-radius: 5px;

    width: 90px;
    height: 28px;

    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: txt 2s ease infinite;

    @keyframes txt {
        0% {
            background-position: 10% 0%;
        }
        50% {
            background-position: 91% 100%;
        }
        100% {
            background-position: 10% 0%;
        }
    }
}

.formStatusOff {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    text-align: center;

    margin: 2px 0 0 20px;
    background-color: #808080;
    border-radius: 5px;

    width: 90px;
    height: 28px;
}

.walletsSpan {
    font-size: 18px;

    background-image: var(--main-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: txt 2s ease infinite;

    cursor: pointer;

    @keyframes txt {
        0% {
            background-position: 10% 0%;
        }
        50% {
            background-position: 91% 100%;
        }
        100% {
            background-position: 10% 0%;
        }
    }
}

.animateBtn {
    border-radius: 20px;
    padding: 12px 40px;
    border: none;
    width: auto;
    align-self: center;
    font-size: 16px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 5px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background: var(--main-grad);
        z-index: -1;
        transition: 0.5s ease-in-out;
        transform: rotate(90deg);
        animation: gradient-animation 3s ease-in infinite;
    }

    @keyframes gradient-animation {
        0% {
            transform: translateX(-40%);
        }
        50% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-40%);
        }
    }
}

.empty {
    font-size: 20px;
    background-image: var(--main-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: txt 2s ease infinite;

    cursor: pointer;

    @keyframes txt {
        0% {
            background-position: 10% 0%;
        }
        50% {
            background-position: 91% 100%;
        }
        100% {
            background-position: 10% 0%;
        }
    }
}

.page_login {
    min-height: 90vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    gap: inherit;

    h1 {
        font-size: 44px;
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: bg_glow 3s ease infinite;
    }

    .content_login {
        display: flex;
        width: 90vw;
        align-items: center;
        flex-direction: column;
        height: fit-content;
        background-color: #0F121B;
        border-radius: 5px;
        padding: 10px;
        gap: 18px;
    }

    span {
        padding: 10px;
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: bg_glow 3s ease infinite;
    }

    label {
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: bg_glow 3s ease infinite;
    }

}

.page_login .login-input {
    width: 80vw;
    height: 2rem;
    background-color: var(--element-bg);
    font-size: 18px;
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
}

.page_login .login-input::placeholder {
    color: rgb(214, 184, 214);
    font-size: large;
    font-weight: 100;
    opacity: 75%;
    transition: 0.3s ease-in-out;
}

.page_login.login-input:focus::placeholder {
    opacity: 0%;
    transition: 0.3s ease-in-out;
}

.page_login .login-btn {
    width: 94%;
    height: 3rem;
    border-radius: 5px;
    padding: 6px 12px;
    font-family: var(--type2);
    font-weight: 600;
    border: none;
    letter-spacing: 0px;
    font-size: large;
    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
}

.page_login .bottom-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin-top: 10px;
    padding: 0px 10px 20px 10px;
    align-items: center;
    background-color: #0F121B;
    border-radius: 5px;
}

.page_login .password-container {
    position: relative;
}

.page_login .eye-password {
    position: absolute;
    right: 1rem;
    top: 48%;
    transform: translateY(-70%);
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.page_login .eye-password:hover {
    opacity: 0.8;
}

.page_login .forgot-password  {
    text-align: end;
}

.page_login .forgot-password button {
    text-align: end;
    border: none;
    font-size: medium;
    padding: 10px;
    background-image: var(--main-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
}

@keyframes bg_glow {
    0% {
        background-position: 10% 0%;
    }

    50% {
        background-position: 91% 100%;
    }

    100% {
        background-position: 10% 0%;
    }
}
.closer {
  position: absolute;
  top: 15px;
  right: 26px; 
  transform: scale(1.8) rotate(45deg);
  z-index: 5;
  cursor: pointer;
  font-size: 25px;
    font-weight: 600;
}

.blur {
  position: fixed; /* Используй fixed для покрытия всего экрана */
  top: 0;
  left: 0;
  height: 100vh; /* Занимает всю высоту экрана */
  width: 100vw; /* Занимает всю ширину экрана */
  z-index: 10; /* Увеличиваем z-index, чтобы элемент был поверх других */
  border-radius: 0; /* Убираем радиус, если не нужно скругление */
  background-color: #0000008e !important; /* Полупрозрачный фон */
  backdrop-filter: blur(10px); /* Эффект размытия на фоне */
  overflow: hidden; /* Отключаем скролл */
}

.selector {
  position: relative;
  top: 63px;
  left: 15px;
}

.opt {
  position: relative;
  padding: 5px;
  font-size: 16px;
  color: #d3d3d3;
  z-index: 4;
  background: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.select {
  font-weight: bolder;
  font-size: 21px;
  background-image: var(--main-grad);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: txt 2s ease infinite;
  cursor: pointer;
}

@keyframes txt {
  0% {
      background-position: 10% 0%;
  }
  50% {
      background-position: 91% 100%;
  }
  100% {
      background-position: 10% 0%;
  }
}

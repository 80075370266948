.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 99vw;
    height: 98vh;
}

.wrapper .title {
    padding: 8px;
    text-align: center;

}

.wrapper h1 {
    font-size: 35px;
    background-image: var(--main-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
}

.wrapper .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background-color: #0F121B;
    width: 95vw;
    color: #c29366;
}

.wrapper .inner-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.wrapper .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 20px;
}

.wrapper .otp-fields {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px;
}

.wrapper .otp-input {
    width: 45px;
    height: 65px;
    text-align: center;
    font-size: 1.5rem;
    border-radius: 10px;
    border: 0;
    color: white;
    background-color: var(--element-bg);
    outline: none;
  


    background-image: var(--main-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
}

.wrapper .submit-button {
    width: 100%;
    height: 3rem;
    border-radius: 5px;
    padding: 6px 12px;
    font-family: var(--type2);
    font-weight: 600;
    border: none;
    letter-spacing: 0px;
    font-size: large;

    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
   
}

.wrapper .submit-button:hover {
    background-color: #896542;
}

.wrapper .error-message {
    color: #ef4444;
}

.wrapper .timer-message {
    padding-bottom: 1rem;
    background-image: var(--main-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
}

.wrapper .resend-link .timer-message {
    font-size: 1.125rem;
    background-image: var(--main-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
}


.border-rgb {
	--angle: 0deg;
    width: 45px;
    height: 65px;
    border-radius: 10px;
	border: 2px solid;
	/* This won't work: */
	border-image: linear-gradient(var(--angle),#735eaf 12.93%, #FFA37B) 1;
	
	animation: 5s rotate linear infinite;
}

@keyframes rotate {
	to {
		--angle: 360deg;
	}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}


@keyframes bg_glow {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

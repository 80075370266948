.page_forgot_password {
    min-height: 88vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: inherit;

    h1 {
        font-size: 40px;
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: bg_glow 3s ease infinite;
    }

    .content_forgot_password {
        display: flex;
        width: 90vw;
        align-items: center;
        flex-direction: column;
        height: fit-content;
        background-color: #0F121B;
        border-radius: 5px;
        padding: 15px;
        gap: 18px;
    }

    span {
        padding: 10px;
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: bg_glow 3s ease infinite;
    }

    label {
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: bg_glow 3s ease infinite;
    }

}

.page_forgot_password .login-input {
    width: 80vw;
    height: 2rem;
    background-color: var(--element-bg);
    font-size: 18px;
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
}

.page_forgot_password .login-input::placeholder {
    color: rgb(214, 184, 214);
    font-size: large;
    font-weight: 100;
    opacity: 75%;
    transition: 0.3s ease-in-out;
}

.page_forgot_password.login-input:focus::placeholder {
    opacity: 0%;
    transition: 0.3s ease-in-out;
}

.page_forgot_password .login-btn {
    width: 95%;
    height: 3rem;
    border-radius: 5px;
    padding: 6px 12px;
    font-family: var(--type2);
    font-weight: 600;
    border: none;
    letter-spacing: 0px;
    font-size: large;
    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
}

.page_forgot_password .bottom-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin-top: 10px;
    padding: 0px 10px 20px 10px;
    align-items: center;
    background-color: #0F121B;
    border-radius: 5px;
}

.page_forgot_password .password-container {
    position: relative;
}

.page_forgot_password .eye-password {
    position: absolute;
    right: 1rem;
    top: 17%;
    transform: translateY(144%);
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.page_forgot_password .password-container-confirm {
    position: relative;
}

.page_forgot_password .eye-password-confirm {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(19%);
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.page_forgot_password .eye-password:hover {
    opacity: 0.8;
}

@keyframes bg_glow {
    0% {
        background-position: 10% 0%;
    }

    50% {
        background-position: 91% 100%;
    }

    100% {
        background-position: 10% 0%;
    }
}
.page {
    
    
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 44px;
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
        animation: bg_glow 3s ease infinite;
    }
}

@keyframes bg_glow {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

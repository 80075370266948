.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    position: relative;
    background: var(--body-bg);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 400px;
    border: solid 3px var(--element-bg);
}

.button {
    margin-top: 15px;
    padding: 10px 15px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 5px;

    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: txt 2s ease infinite;

    cursor: pointer;

    @keyframes txt {
        0% {
            background-position: 10% 0%;
        }
        50% {
            background-position: 91% 100%;
        }
        100% {
            background-position: 10% 0%;
        }
    }
}

.cancelButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    border: none;
    background: none;
    cursor: pointer;
    transform: rotate(45deg);
    filter: sepia(0.5) saturate(7000%) hue-rotate(200deg) brightness(80%)
        drop-shadow(2px 2px 5px rgba(133, 66, 160, 0.5));
}

.cancelButtonImg {
    width: 25px;
    height: 25px;
}

.title {
    font-size: 20px;
    background-image: var(--main-grad);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 200%;
    animation: txt 2s ease infinite;

    cursor: pointer;

    @keyframes txt {
        0% {
            background-position: 10% 0%;
        }
        50% {
            background-position: 91% 100%;
        }
        100% {
            background-position: 10% 0%;
        }
    }
}

@keyframes borderAnimation {
  0% {
      border-color: #e0cce5;
  }
  25% {
      border-color: #e370ff;
  }
  50% {
      border-color: #efadff;
  }
  75% {
      border-color: #e370ff;
  }
  100% {
      border-color: #e0cce5;
  }

   
}

.balance-plate {
  padding: 25px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 20px; /* Закругление углов */
  border: 4px solid transparent; /* Сделаем бордер прозрачным, чтобы градиент был виден */
  box-sizing: border-box;
  background-image: var(--body-bg); /* Фон с градиентом */
  background-clip: border-box; /* Ограничиваем фон границей */
  animation: borderAnimation 4s infinite linear; /* Анимация изменения цвета бордера */
  background-color: --body-bg !important;
}

.balance-plate::before {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  background: var(--body-bg);
  border-radius: 20px; /* Закругляем углы */
  z-index: -1; /* Размещаем за основным контентом */
  animation: borderAnimation 4s infinite linear; /* Анимация фона */
}


.formStatusOff {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  text-align: center;


  background-color: #808080;
  border-radius: 5px;
  padding: 6px 12px;
}

.balance-plate_leftCol {
    max-width: 70%;
}

.balance-plate_leftCol > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.status-div {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
.balance-plate_leftCol > div > span:first-child {
    font-size: 22px;
    font-weight: 600;
}
.balance-plate-edit-img {
    width: 18px;
    height: 18px;
    border-radius: 5px;
    border: none;
    background-image: var(--main-grad);
    padding: 14px;
}

.balance-plate_leftRight{
  width: 46px;
  height: 46px;
}
.page_registr {
    min-height: 74vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 44px;
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: bg_glow 3s ease infinite;
    }

    .content_registr {
        display: flex;
        width: 90vw;
        align-items: center;
        flex-direction: column;
        height: fit-content;
        background-color: #0F121B;
        border-radius: 5px;
        padding: 10px;
        gap: 18px;
    }

    span {
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: bg_glow 3s ease infinite;
    }

    label {
        background-image: var(--main-grad);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 200%;
        animation: bg_glow 3s ease infinite;
    }

}

.page_registr .registr-input {
    width: 80vw;
    height: 2rem;
    background-color: var(--element-bg);
    font-size: 18px;
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 5px;
    outline: none;
    color: #ffffff;
}

.page_registr .registr-input::placeholder {
    color: rgb(214, 184, 214);
    font-size: large;
    font-weight: 100;
    opacity: 75%;
    transition: 0.3s ease-in-out;
}

.page_registr .registr-input:focus::placeholder {
    opacity: 0%;
    transition: 0.3s ease-in-out;
}

.page_registr .registr-btn {
    width: 94%;
    height: 3rem;
    border-radius: 5px;
    padding: 6px 12px;
    font-family: var(--type2);
    font-weight: 600;
    border: none;
    letter-spacing: 0px;
    font-size: large;


    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
}

.page_registr .bottom-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    padding: 10px;
    align-items: center;
}

.page_registr .password-container {
    position: relative;
  }
  
  .page_registr .eye-password {
    position: absolute;
    right: 1rem;
    top: 51%;
    transform: translateY(10%);
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  
  .page_registr .eye-password:hover {
    opacity: 0.8;
  }

  .page_registr .forgot-password {
    text-align: end;
    margin-top:10px ;
  }

@keyframes bg_glow {
    0% {
        background-position: 10% 0%;
    }

    50% {
        background-position: 91% 100%;
    }

    100% {
        background-position: 10% 0%;
    }
}
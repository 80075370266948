.header {
    display: flex;
    justify-content: start;
    align-items: center;
}

.txt {
    margin-left: 15px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--main-grad);
    background-clip: text;
    background-size: 200% 200%;
    animation: txt 2s ease infinite;

    @keyframes txt {
        0% {
            background-position: 10% 0%;
        }
        50% {
            background-position: 91% 100%;
        }
        100% {
            background-position: 10% 0%;
        }
    }
}

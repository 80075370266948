@font-face {
    font-family: Mukta;
    src: url(../fonts/Mukta-Regular.ttf);
}

@font-face {
    font-family: Mulish;
    src: url(../fonts/Mulish-Regular.ttf);
}

@font-face {
    font-family: Diabolic;
    src: url(../fonts/DidactGothic-Regular.ttf);
}

:root {
    margin: 0;
    padding: 0;
    font-family: Mulish, sans-serif;
    --font-all: #dfdfdf;
    --small-btn-bg: #414558;
    --body-bg: #141723;
    --element-bg: #273140;
    --text-grey: #646774;
    --main-grad: linear-gradient(130deg, #0400f0 0%, #e370ff 60%, #efadff 100%);
    --alte-grad: linear-gradient(45deg, #0400f0 0%, #e370ff 100%);
    --type1: Mukta;
    --type2: Mulish;
    --type3: Diabolic;
}

body {
    overflow-x: hidden;
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: var(--body-bg);
    color: var(--font-all);
    font-family: var(--type3);
}

.slice {
    color: var(--text-grey);
}

button {
    color: var(--font-all);
}

p {
    margin: 0;
}

.Toastify__progress-bar--default {
    background: var(--main-grad);
}

.styled-block {
    box-sizing: border-box;
    padding: 25px;
    border-radius: 20px;
    background-color: var(--element-bg);
}

.styled-block-small {
    box-sizing: border-box;
    padding: 11px;
    border-radius: 20px;
    background-color: var(--element-bg);
}

.button-img {
    width: 28px;
    height: 28px;
}

.mainFrame {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 30px;
  width: 95dvw;

}

/* header */

.mainFrame_header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.mainFrame_header > h1 {
    margin: 0;
    line-height: 32px;
    font-weight: 600;
}

.mainFrame_header > h1 > span:nth-child(1) {
    font-size: 16px;
    color: var(--text-grey);
}

.mainFrame_header-avatar {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #0400f0;
    background-image: var(--main-grad);
}

/* content */
.mainFrame_balance-button-and-money {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.balance-btn {
    background: var(--main-grad);
    border-radius: 20px;
    padding: 6px 12px;
    font-family: var(--type2);
    font-weight: 600;
    border: none;
    letter-spacing: 0px;
}

.balance-btn:hover {
    animation: 11s forwards linear alter-grad;
}

.mainFrame_balance-button-and-money > hr {
    width: 90%;
    color: var(--text-grey);
}

.mainFrame_balance-controls-button {
    padding: 0 20px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
}

.mainFrame_balance-button-and-money > h2 {
    font-size: 32px;
}

.utility-btn {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-grey);
    transition: 0.3s ease-in-out;
}

.utility-btn:hover {
    color: var(--font-all);
}

.utility-btn > img {
    border-radius: 13px;
    background-color: var(--small-btn-bg);
    padding: 14px;
}

.utility-btn > p {
    margin: 0;
    font-weight: 600;
}

/* Activity */
/* footer-controll */
/* payment */

/*toast*/
.Toastify__toast-container {
    border-radius: 0;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0px;
    width: 100% !important;
}

.spinner-load {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    align-items: center;
    justify-content: center;
}
.spinner-wrapper {
    width: 300px;
}
.spinner-title {
    position: relative;
    top: 48%;
    left: 30%;
    font-size: 28px;
    background-image: var(--main-grad);
    color: transparent;
    display: inline-block;
    width: max-content;
    background-clip: text;
    width: fit-content;
}

/* Root.css */
.Toastify__toast {
  /* background: var(--main-grad) !important;
  color: var(--main-grad) !important; */
}
.Toastify__progress-bar {
  background: var(--main-grad) !important;
}


.spinner {
    width: 100%;
    -webkit-animation: yt-spin 0.8s infinite linear;
    -moz-animation: yt-spin 0.8s infinite linear;
    -o-animation: yt-spin 0.8s infinite linear;
    animation: yt-spin 0.8s infinite linear;
}

@media screen and (max-width: 650px) {
    .block {
        width: 95dvw;
    }
}

@keyframes alter-grad {
    0% {
        background-position: 40% 100%;
    }

    100% {
        background-position: 40% 10%;
    }
}

@keyframes yt-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

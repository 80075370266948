.tg-btn {
    width: 70px;
    height: 30px;
    border-radius: 5px;
    padding: 6px 12px;
    font-family: var(--type2);
    font-weight: 600;
    border: none;
    letter-spacing: 0px;
    font-size: large;

    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: bg_glow 3s ease infinite;
}

.tg-btn iframe {
    width: 50px;
    height: 20px;
    font-size: 5px;
}

.tg-btn iframe html body {
    font-size: 5px;
}

@keyframes bg_glow {
    0% {
        background-position: 10% 0%;
    }

    50% {
        background-position: 91% 100%;
    }

    100% {
        background-position: 10% 0%;
    }
}

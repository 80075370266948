.editing-project-page {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 30px;
    padding-bottom: 120px;
}

.editing-project-page_title {
    text-align: left;
    font-weight: 600;
    background-image: var(--main-grad);
    color: transparent;
    display: flex;
    width: max-content;
    background-clip: text;
    width: fit-content;
    white-space: nowrap;
}

.editing-project-page_content {
    position: relative;
    border-radius: 5px !important; 
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.edit-project-page_description {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.edit-project-page_description > p {
    font-size: 22px;
}

.edit-description {
    text-decoration: underline;
    color: var(--text-grey);
}

.edit-project-page_link {
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.edit-project-page_link > p {
    font-size: 22px;
}

.edit-project-link {
    display: block;

  
    width: 100%;
 
    text-align: left;
    word-break: break-all;
}

.edit-project_status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: 18px;
}

.edit-project_status > p {
    font-size: 22px;
}

.edit-project_status_active {
    color: green;
    font-size: 16px;
    font-weight: 400;
    background-image: black;
}
.editing-project-page_title-wrapper {
    display: flex;
    flex-wrap: nowrap;
}
.edit-project_status_pending {
    color: var(--text-grey);
}

.edit-project_status > p,
.edit-project-page_url > p,
.edit-project_name > p,
.edit-project_description > p,
.edit-project_api > p,
.edit-project_key > p,
.edit-project_token > p {
    font-size: 22px;
}

.edit-project_api > span {
    word-break: break-all;
}

.edit-project-page_url,
.edit-project_description,
.edit-project_name,
.edit-project_api,
.edit-project_key,
.edit-project_token,
.edit-project_buttons {
    word-break: break-all;
    display: flex;
    flex-direction: column;
    gap: 13px;
}
.edit-project_token-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
}
.edit-project_token_warn {
    display: block;
    font-size: 14px;
    color: var(--text-grey);
}
.edit-project_button {
    border-radius: 20px;
    border: 1px solid var(--text-grey);
    background-color: transparent;
    padding: 10px 0px;
    text-align: center;
    font-size: 20px;
}

.edit-logo-div {
    position: absolute;
    right: 25px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;
    border-radius: 5px;

    cursor: pointer;

    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: txt 2s ease infinite;
}

.edit-logo {
    width: 35px;
    height: 35px;
}

@keyframes txt {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

.edit-project_status {
    display: flex;
    justify-content: flex-start;
}

.edit-project_status-active {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    text-align: center;

    margin: 1px 0 0 5px;
    border-radius: 5px;

    width: 90px;
    height: 28px;

    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: txt 2s ease infinite;
}

@keyframes txt {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

.edit-project_status-dis {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    text-align: center;

    margin: 1px 0 0 5px;
    background-color: #808080;
    border-radius: 5px;

    width: 90px;
    height: 28px;
}

.mainFrame_header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: end;
}

.logout{
  width: 28px;
  height: 28px;
}

.hwel {
    margin: 0;
    line-height: 32px;
    font-weight: 600;
}



.welcome {
  font-size: 16px !important;
  color: var(--text-grey) !important;
}


.mainFrame_header-avatar {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #0400f0;
    background-image: var(--main-grad);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainFrame_header-avatar > img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

/* content */

.balance-btn {
    background: var(--main-grad);
    padding: 6px 12px;
    font-family: var(--type2);
    font-weight: 600;
    border: none;
    letter-spacing: 0px;
}

.balance-btn-h {
    background: var(--main-grad);
    border-radius: 5px;
    padding: 6px 12px;
    font-family: var(--type2);
    font-weight: 600;
    border: none;
    letter-spacing: 0px;

    font-size: 14px;
    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: txt 2s ease infinite;
}

@keyframes txt {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

.balance-btn:hover {
    animation: 11s forwards linear alter-grad;
}

.mainFrame_balance-button-and-money > hr {
    width: 90%;
    color: var(--text-grey);
    margin: 5px;
}

.mainFrame_balance-controls-button {
    padding: 0 20px;
    margin-top: 16px;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;

    @media (max-width: 388px) {
        padding: 0px 0px;
    }

    @media (max-width: 339px) {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
    }
}

.mainFrame_balance-button-and-money > h2 {
    font-size: 32px;
}



.utility-btn {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-grey);
    transition: 0.3s ease-in-out;
    text-decoration: none;
    text-align: center;
    @media (max-width: 339px) {
        padding: 0px 20px;
    }
}

.utility-btn:hover {
    color: var(--font-all);
}

.utility-btn > img {
    border-radius: 13px;
    background-color: var(--small-btn-bg);
    padding: 14px;
}

.utility-btn > p {
    margin: 0;
    font-weight: 600;
}

.balance-placeholder {
    height: 49px;
    border-radius: 8px;
    width: 70dvw;
    background-size: 400%;
    max-width: 450px;
    background-image: linear-gradient(
        62deg,
        var(--element-bg) 0%,
        var(--body-bg) 55%,
        rgba(252, 252, 252, 0) 100%,
        var(--element-bg) 100%
    );
    animation: skeleton-loading 1.5s linear infinite alternate-reverse;
}

@keyframes skeleton-loading {
    0% {
        background-position-x: 0%;
    }

    100% {
        background-position-x: 150%;
    }
}
.mainFrame_header {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.mainFrame_header-left {
  display: flex;
  align-items: center;
  gap: 15px; /* расстояние между аватаркой и текстом */
}

.mainFrame_header > h1 {
  margin: 0;
  line-height: 32px;
  font-weight: 600;
  text-align: left;
}

.logout-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.mainFraim {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 30px;
   
}

.withdraw-details{
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;

}

.btn-confirm{
  margin-top: auto;
  margin-top: 20px;
}

.withdraw-header {
    text-align: left;
    font-weight: 600;
    background-image: var(--main-grad);
    color: transparent;
    display: inline-block;
    width: max-content;
    background-clip: text;
    width: fit-content;
}

.whithdraw-page-content>span:first-child {
    font-size: 30px;
    
}

.whithdraw-page-conten-data {
    font-size: 18px;
    line-height: 30px;
}


.wallet-value {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 22px;
}

.whithdraw-page-content {
  min-height: 80vh !important;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    align-items: start;
}

.whithdraw-page-content>hr {
    width: 100%;
}

.withdraw-controller {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 20px;
    font-size: 18px;
}

.withdraw-controller-input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-size: 25px;
    border-radius: 20px;
    padding: 5px 20px;
}

.withdraw-warning {
    display: block;
    font-size: 14px;
    color: var(--text-grey);
    text-align: left;
    gap: 5px;
    padding: 3px 0px;
    line-height: 16px;
}

.withdraw-btc-accept {
    border-radius: 20px;
    font-size: 22px;
    border: none;
    padding: 10px;
    background-image: var(--main-grad)
}

.withdraw-input-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.strong-details{
  font-size: 18px;
}

.withdraw-detail {
  display: flex;

  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc; /* Тонкая линия для всех */
}

.withdraw-detail:last-child {
  border-bottom: none !important; /* Убираем линию после последнего элемента */
}

.withdraw-detail.total {
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff; /* Жирная линия для Total */
}
.strong-details {
  font-size: 18px;
  font-weight: bold;
}

.withdraw-details span {
  font-size: 18px;
}
.withdraw-detail:nth-child(3) {
  border-bottom: none; /* Убираем бордер для Chain Fee */
}



.btn-dones {
  font-family: var(--type2);
  border-radius: 5px;
  padding: 6px 12px;
  border: none;
  width: auto;
  align-self: center;
  font-size: 13px;
  color: white;
  background-color: #0400f0;
  background: var(--main-grad);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.headerContainer {
    left: 5px;
    top: 5px;
}

.arrow {
    position: relative;
    transform: rotate(180deg);
    height: 30px;
    width: 20px;
    margin-top: 7px;
    filter: sepia(0.5) saturate(7000%) hue-rotate(200deg) brightness(80%)
        drop-shadow(2px 2px 5px rgba(133, 66, 160, 0.5));
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 30px;
}

.profile_title {
    text-align: left;
    font-weight: 600;
    background-image: var(--main-grad);
    color: transparent;
    display: inline-block;
    width: max-content;
    background-clip: text;
    width: fit-content;
    margin-bottom: -15px;
}

.profile_names-block {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.profile-currency-block {
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;
}

.profile_names-block > div {
    display: flex;
    justify-content: start;
    align-items: baseline;
    font-size: 24px;
    gap: 4px;
}

.profile_contacts {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 35px;
    font-size: 24px;
    padding: 25px 25px 35px 25px !important;
    min-width: 350px;
}

.profile_first-name,
.profile_middle-name,
.profile_last-name,
.profile_mail,
.profile_phone,
.profile_wallet {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    gap: 7px;
}



.invoice-input-extend {
    color: white;
    width: 100%;
    font-size: 28px;
    outline: none;
    border: none;
    background-color: white;
    border-bottom-width: medium;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    background-color: inherit;
    border-bottom: solid 2px var(--text-grey);
}

::placeholder {
    opacity: 0.4;
    color: white;
}

::-ms-input-placeholder {
    color: white;
}

.PhoneInputInput {
    color: white;
    width: 100%;
    font-size: 28px;
    outline: none;
    border: none;
    border-bottom-width: medium;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
    background-color: inherit;
    border-bottom: solid 2px var(--text-grey);
}

.profile_button {
    width: 100%;
    border-radius: 20px;
    border: 1px solid var(--text-grey);
    background-color: transparent;
    padding: 10px 0px;
    text-align: center;
    font-size: 20px;
}

.btn-change-one {
    position: absolute;
    right: 39px;
    top: 267px;

    height: 30px;
    border-radius: 5px;

    cursor: pointer;
}

.btn-change-two {
    position: absolute;
    right: 40px;
    top: 374px;
    cursor: pointer;
    font-size: 13px;
}

.btn-change-three {
    position: absolute;
    right: 40px;
    top: 480px;
    cursor: pointer;
    font-size: 13px;
}
.profile_phone-container {
  position: relative;
  transition: padding-right 0.3s ease; 
  padding-right: 55px;  /* Контейнер для позиционирования */
}

.username-container.no-telegram {
  position: relative;
  padding-right: 175px;
}

.username-container.has-telegram {
  position: relative;
  padding-right: 0px;
}


.btn-change-two {
  position: absolute;
  right: 0px;
  top: 80%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 13px;
}

.profile_mail-container {
  position: relative;
  padding-right: 0px; /* Отступ по умолчанию */
  transition: padding-right 0.3s ease; /* Плавное изменение отступа */
}

.profile_mail-container.has-input {
  padding-right: 55px; /* Когда есть текст */
}

.profile_mail-containers {
  position: relative;
  padding-right: 0px; /* Отступ по умолчанию */
  transition: padding-right 0.3s ease; /* Плавное изменение отступа */
}


.profile_mail {
  position: relative;
}

.btn-change-four{
  position: absolute;
  right: 0px;
  top: 60%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 13px;
}

.btn-change-three {
  position: absolute;
  right: 0px;
  top: 80%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 13px;
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@font-face {
    font-family: Mukta;
    src: url(./fonts/Mukta-Regular.ttf);
}

@font-face {
    font-family: Product;
    src: url(./fonts/productsans.ttf);
}

@font-face {
    font-family: Poppins;
    src: url(./fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: Mulish;
    src: url(./fonts/Mulish-Regular.ttf);
}

@font-face {
    font-family: Diabolic;
    src: url(./fonts/DidactGothic-Regular.ttf);
}

:root {
  max-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: Mulish, sans-serif;
    --font-all: #dfdfdf;
    --small-btn-bg: #414558;
    --body-bg: #141723;
    --element-bg: #273140;
    --text-grey: #646774;
    --sub-grad: linear-gradient(102.05deg, #05051c 40.6%, #080640f2 91.37%);
    --main-grad: linear-gradient(100.57deg, #735eaf 12.93%, #ffa37b 121.02%);
    --alte-grad: linear-gradient(102.05deg, #836dd6 40.6%, #ffa37b 91.37%);
    --type1: Mukta, sans-serif;
    --type2: Mulish, sans-serif;
    --type3: Poppins, sans-serif;
    --type4: Product, sans-serif;
}

/*4c358a*/
body {
    overflow-x: hidden;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-color: var(--body-bg);
    color: var(--font-all);
    font-family: var(--type3);
}

p {
    color: white;
}

a {
    color: white;
    text-decoration: none;
    text-underline-offset: none;
}

.slice {
    color: var(--text-grey);
}

.invoice-input {
    color: white;
    width: 100%;
    font-size: 28px;
    outline: none;
    border: none;
    background-color: inherit;
    border-bottom: solid 2px var(--text-grey);
}

a:visited {
    color: white;
}

button {
    color: var(--font-all);
}

hr {
    width: 100%;
}

p {
    margin: 0;
}

.block {
    width: 550px;
    box-sizing: border-box;
}

.styled-block {
    box-sizing: border-box;
    padding: 25px;
    border-radius: 20px;
    background-color: var(--element-bg);
}

.styled-block-small {
    box-sizing: border-box;
    padding: 11px;
    border-radius: 20px;
    background-color: var(--element-bg);
}

h1 {
    padding: 0;
    margin: 0;
}

.button-img {
    width: 28px;
    height: 28px;
}

/* header */

.mainFrame_header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.mainFrame_header > h1 {
    margin: 0;
    line-height: 32px;
    font-weight: 600;
}

.mainFrame_header > h1 > span:nth-child(1) {
    font-size: 16px;
    color: var(--text-grey);
}

.mainFrame_header-avatar {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: #0400f0;
    background-image: var(--main-grad);
    align-self: flex-start;
    margin-top: 13px;
}

/* content */
.mainFrame_balance-button-and-money {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainFrame_balance-button-and-money > h2 {
    margin: 10px 0px;
}
.balance-btn {
    background: var(--main-grad);
    border-radius: 5px;
    padding: 6px 12px;
    font-family: var(--type2);
    font-weight: 600;
    border: none;
    letter-spacing: 0px;
}

.small {
    width: fit-content;
    font-weight: 400;
    text-transform: lowercase;
    font-size: 13px;
}

.balance-btn:hover {
    animation: 11s forwards linear alter-grad;
}

.mainFrame_balance-button-and-money > hr {
    width: 90%;
    color: var(--text-grey);
}

.mainFrame_balance-button-and-money > h2 {
    font-size: 32px;
}

.utility-btn:hover {
    color: var(--font-all);
}

.utility-btn > img {
    border-radius: 13px;
    background-color: var(--small-btn-bg);
    padding: 14px;
}

.utility-btn > p {
    margin: 0;
    font-weight: 600;
}

.btn-anim {
    background-image: var(--main-grad);
    background-size: 200% 200%;
    animation: txt 2s ease infinite;
}

@keyframes txt {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

/* Activity */
/* footer-controll */
/* payment */

.Toastify__close-button {
    display: none !important;
}

textarea,
input.text,
input[type='text'],
input[type='button'],
input[type='number'],
input[type='tel'],
input[type='submit'],
.input-checkbox {
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0px 0px;
}

#telegram-login-container {
  display: none;
}

#telegram-login-container {
  display: none;
}

@media screen and (max-width: 650px) {
    .block {
        width: 95dvw;
    }
}

@keyframes alter-grad {
    0% {
        background-position: 40% 100%;
    }

    100% {
        background-position: 40% 10%;
    }
}
